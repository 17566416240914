import { HttpParams } from "@angular/common/http";
import { AuthService } from "./../authServices/auth.service";
import { Injectable } from "@angular/core";
import { Observable, switchMap } from "rxjs";
import { CommonService } from "@sportyano/core/services/shard/common.service";
import { environment } from "src/environments/environment";
import { PlayerResponse } from "@sportyano/core/models/single-player.model";
import { IProfileResponse } from "@sportyano/core/models/account/profile";
import { IPaginatedResponse, IResponse } from "@sportyano/core/models/response/response.interface";
import { IAccount } from "@sportyano/core/models/account/account";

@Injectable({
	providedIn: "root",
})
export class PlayerService {
	constructor(
		private commonServices: CommonService,
		private auth: AuthService
	) {}

	getPlayerAccountData(routerName: string) {
		return this.commonServices.CommonGetRequests(
			`${environment.baseUrl}${routerName}`
		);
	}

	updatePlayerAccountData(routerName: string, model: any) {
		return this.commonServices.CommonPutRequests(
			model,
			`${environment.baseUrl}${routerName}`
		);
	}

	updatePlayerPhone(model: any) {
		return this.commonServices.CommonPostRequests(
			model,
			`${environment.baseUrl}phone-numbers/update`
		);
		// .pipe(
		// 	switchMap((res: any) => {
		// 		console.log('res',res);
		// 		model.token = res.token;
		// 		model.otp = res.otp;
		// 		return this.commonServices
		// 			.CommonPostRequests(
		// 				model,
		// 				`${environment.baseUrl}phone-numbers/verify`
		// 			)
		// 			.pipe();
		// 	})
		// );
	}

	public getPlayerInfoData(
		routerName: string,
		profileId: number
	): Observable<IProfileResponse | any> {
		return this.commonServices.CommonGetRequests(
			`${environment.baseUrl}${routerName}/${profileId}/profile`
		);
	}
	//update player profile api
	updatePlayerProfile(routerName: string, model: any, player_Id: number) {
		return this.commonServices.CommonPutRequests(
			model,
			`${environment.baseUrl}${routerName}/${player_Id}/profile`
		);
	}

	createViewerProfile(routerName: string, model: any, player_Id: number) {
		return this.commonServices.CommonPostRequests(
			model,
			`${environment.baseUrl}${routerName}/${player_Id}/profile`
		);
	}

	deleteplayermedia(playerId: number, mediaId: number) {
		return this.commonServices.CommonDeleteRequest(
			`${environment.baseUrl}players/${playerId}/media/${mediaId}`
		);
	}

	addAvatar(routerName: string, file: FormData, player_Id: number) {
		return this.commonServices.uploadFile(file).pipe(
			switchMap((res: any) => {
				return this.commonServices.CommonPostRequests(
					{ path: res.path },
					`${environment.baseUrl}${routerName}/${player_Id}/profile/avatars`
				);
			})
		);
	}

	addAttachments(file: FormData, player_Id: number) {
		return this.commonServices.uploadFile(file).pipe(
			switchMap((res: any) => {
				return this.commonServices.CommonPostRequests(
					{ path: res.path },
					// `${environment.baseUrl}players/${player_Id}/profile/attachments`
					`${environment.baseUrl}players/${player_Id}/pending-players-profiles/attachments`
				);
			})
		);
	}

	getPlayers(params?: any) {
		let user = this.auth.getUserData();
		return this.commonServices.CommonGetWithParamRequests(
			`${environment.baseUrl}home-page/list-players`,
			params
		);
	}

	public getSelectedPlayers() {
		return this.commonServices.CommonGetRequests(
			`${environment.baseUrl}home-page/main-page-players`
		);
	}

	getSinglePlayers(playerId: string): Observable<PlayerResponse> {
		let user = this.auth.getUserData();
		let params = new HttpParams();
		params = params.set("user_id", user?.id || "false");
		return this.commonServices.CommonGetWithParamRequests(
			`${environment.baseUrl}home-page/player/${playerId}`,
			params
		);
	}
}
